@import "../../../styles/settings.scss";

.boxTitle {
  padding: 0;
  font-family: $font-title;
  text-shadow:  -0.5px -0.5px 0 $color-top, 0.5px -0.5px 0 $color-top, -0.5px 0.5px 0 $color-top, 0.5px 0.5px 0 $color-top;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: -1px;
}

@media (max-width: 1000px) {

  .boxTitle {
    font-size: 60px;
    padding-left: $base-padding;
  }
}
