@import "../../../styles/settings.scss";

.root {
  background-color: white;
  padding-bottom: 30px;
  @extend %baseBorderImage;
}

.headerBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  height: 570px;

  .imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
    max-height: 500px;
    }
  }
  .textBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    .title1 {
      font-size: 55px;
      color: $color-top;
      font-weight: 900;
      padding-bottom: $base-padding;
      font-family: $font-title;
      text-shadow:  -0.5px -0.5px 0 $color-top, 0.5px -0.5px 0 $color-top, -0.5px 0.5px 0 $color-top, 0.5px 0.5px 0 $color-top;
    }

    .title2 {
      color: $color-top;
      font-size: 48px;
      font-family: $font-text;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;

    }

    .title3 {
      color: $color-grey-text;
      font-size: 35px;
      font-family: $font-text;
      font-weight: 900;
      text-transform: uppercase;
      padding-top: $base-padding;
    }
  }
}
@media (max-width: 1000px) {

  .headerBox {
    .imageBox {
        display: flex;
      align-items: center;
      img {
        max-height: 400px;
      }
    }

    .textBox {

      .title1 {
        font-size: 48px;
      }

      .title2 {
        font-size: 40px;
      }

      .title3 {
        font-size: 20px;
      }
    }
  }

}

@media (max-width: 770px) {

  .headerBox {
    flex-direction: column-reverse;

    .imageBox {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 300px;
      }
    }

    .textBox {
      padding-left: $base-padding;

      .title1 {
        font-size: 38px;
        padding-bottom: 0;
        padding-top: $base-padding;
      }

      .title2 {
        font-size: 40px;
        padding-top: $base-padding;
      }

      .title3 {
        font-size: 20px;
        padding-top: $base-padding;
      }
    }
  }

}

