@import "../../../styles/settings.scss";

.root {
  background-color: $color-grey-light;
  padding: 80px 0;
  box-shadow: inset 0 0 1em $color-grey-line;

  .box {
    padding: 0 50px;
  }
}

.aboutBox {
  display: flex;
  flex-direction: row;
  padding: 30px 0 80px 0;


  .description {
    font-family: $font-text;
    display: flex;
    flex-direction: column;
    width: 80%;
    span {
      font-size: 22px;
      padding-bottom: $base-padding;
    }
    @extend %font-text;
    align-items: center;
    justify-content: center;
    text-align: start;
    font-size: 16px;
    height: 100%;
    padding-right: 50px;
  }
}

.imageBox {

  img {
    max-width: 380px;

  }
}

.photoWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-top: 40px;

  img {
    max-width: 200px;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 2px 4px 10px 4px $color-grey-line;

  }
}

@media (max-width: 1000px) {

.root {
  padding: 40px 0;

  .box {
    padding-left: 40px;
  }
}
  .aboutBox {
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: $base-padding-bottom;

    .description {
      width: 100%;
      padding: 0 24px;
      font-size: 16px;
      span {
        font-size: 22px;

      }
    }
  }

  .imageBox {
    padding-top: $base-padding-bottom;
    display: flex;
    justify-content: center;

    img {
      max-width: 300px;
      padding: 10px;
    }
  }

  .photoWrapper {
    justify-content: center;
    padding-top: 0;

    img {
      width: 150px;
      padding: 2px;
      margin: 10px;
    }
  }
  }

