@import "../../../styles/settings.scss";

.root {
  background-color: $color-grey-border;
  padding: 80px 0;
  box-shadow: inset 0 0 1em $color-grey-line;

  .box {
    padding: 0 50px;
  }
}

.backendSkills{
  padding-bottom: $base-padding;
}

.frontendSkills, .backendSkills, .tools {
  padding-top: 60px;
}

.iconBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

