@import "../../../styles/settings.scss";

.root {
  padding-top: 80px;

  .box {
    padding: 0 50px;
  }
}

.boxContact {
  display: flex;
  flex-direction: row;
  padding-bottom: $base-padding-bottom;

  .text {
    display: flex;
    flex-direction: column;
    font-size: 50px;
    width: 100%;
    justify-content: center;

    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: $base-padding-bottom;
      padding-bottom: $base-padding;
      text-decoration: none;
      color: $color-top;

      &:hover {
        .icon {
          color: #ddb246;
        }
      }

    }

    .link {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding-right: $base-padding-bottom;
    }
  }
  .image {

    img {
      max-height: 500px;
    }
  }
}
@media (max-width: 1000px) {
  .root {
    .box {
      padding: 0;
    }
  }

  .boxContact {
    .text {
      font-size: 25px;

      a {
        text-align: end;
        padding-right: 0;
      }

      .link {
        font-size: 16px;
        padding-left: $base-padding;
      }
    }

    .image {
      img {
        max-height: 300px;
        padding-bottom: $base-padding;
      }
    }

  }

}

@media (max-width: 770px) {

  .root {
    .box {
      padding-left: 40px;
    }
  }
  .boxContact {
    flex-direction: column-reverse;

    .text {
      padding-left: $base-padding;
      font-size: 30px;

      a {
        flex-direction: row-reverse;
      }
      .link {
        font-size: 16px;
        padding-left: $base-padding;
        }
    }

    .image {
      display: flex;
      justify-content: center;

      img {
        max-height: 300px;
        padding-bottom: $base-padding;
      }
    }


  }
}
