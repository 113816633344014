@font-face {

  font-family: 'RomanS' ;
  src: local('RomanS'), url("../assets/fonts/romans__.ttf") format('truetype');
}

@font-face {
  font-family: 'MS Reference Sans Serif';
  src: local('MS Reference Sans Serif'), url("../assets/fonts/refsan__.ttf") format('truetype');
}
