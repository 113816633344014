$base-size: 14px;
$base-padding: 24px;
$base-padding-bottom: 50px;

// COLORS
$color-top: #111111;
$color-white: #ffffff;
$color-grey-light: #fafafa;
$color-grey-medium: #f5f5f5;
$color-grey-dark: #f2f3f5;
$color-grey-text: #a4a4a4;
$color-grey-border: #e6e6e6;
$color-grey-line: #dddddd;

//FONTS

$font-text: "RomanS";
$font-title: "MS Reference Sans Serif";

%font-text {
  font-family: $font-text;
  font-weight: bold;
}

//variables
$background-color-transition: 250ms;
$color-transition: 250ms;
$border-color-transition: 250ms;

//BORDER
%baseBorderImage {
  box-shadow: 2px 4px 10px 4px $color-grey-line;
  background-color: $color-white;
  border-radius: 10px;
}

//HOVER ANIMATIONS
%hoverAnimation {
  transition: background-color $background-color-transition linear, color $color-transition linear, border-color $border-color-transition linear;
  transform: translate(0, 0);
}

%backgroundAnimatable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  content: '';
  transition: opacity 1.5s ease-in-out;
}
