@import "../../../styles/settings.scss";

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $base-padding 0;

  .line {
    height: 1px;
    width: 100%;
    background-color: $color-top;
  }

  .text {
    width: 50%;
    display: flex;
    justify-content: center;
    font-family: $font-text;
    font-weight: 900;
    font-size: 22px;
    text-transform: uppercase;
  }
}

@media (max-width: 992px) {
  .box {
    .text {
      padding: 0 10px;
      font-size: 20px;
    }
  }
}
