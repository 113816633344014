@import "../../../styles/settings.scss";

.box {
  padding: $base-padding-bottom 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .imageBox {
    flex: 2;
    img {
      width: auto;
      height: 400px;
      padding: 20px;
      @extend  %baseBorderImage;
    }
  }

  .description {
    font-size: 18px;
    width: 80%;
    height: 100%;
    flex: 2;
    font-family: $font-text;
    font-weight: 900;
    padding-left: 50px;

    .link {
      padding-top: $base-padding;
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        border-radius: 10px;
        box-shadow: 2px 4px 10px 4px $color-grey-line;
        justify-content: center;
        padding: 20px;
        width: 30%;
        margin: 10px 0;
        background-color: white;
        text-decoration: none;
        color: $color-top;
        text-transform: uppercase;
        font-family: $font-title;
        &:hover {
          color: $color-grey-text;
        }

      }
    }
  }

  .name {
    flex:2;
    text-transform: uppercase;
    font-family: $font-text;
    font-weight: 900;
    font-size: 50px;
  }
}

@media (max-width: 1000px) {

  .box {
    padding: $base-padding 0;
    flex-direction: column;

    .imageBox {
      img {
        width: auto;
        height: 250px;
        padding: 10px;
      }
    }

    .name {
      width: 100%;
      font-size: 40px;
      padding-top: $base-padding-bottom;
      padding-left: $base-padding;
    }

    .imageBox {
      padding-top: $base-padding-bottom;
    }

    .description {
      font-size: 16px;
      width: 100%;
      padding-top: $base-padding-bottom;
      padding-left: $base-padding;
    }
  }

}

@media (max-width: 600px) {

  .box {
    .description {
      .link {
        a {
          width: 50%;
        }
      }
    }
  }

}
