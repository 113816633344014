@import "../../../styles/settings.scss";

.root {
  background-color: $color-top;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 55px;
  }
}
.boxTopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90px;
}
.list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: inline-block;
      line-height: 5px;
      margin-right: 30px;


      a {
        font-family: $font-text;
        letter-spacing: 2px;
        color: white;
        font-weight: bold;
        font-size: 18px;

        &:hover {
          color: $color-grey-text;
          text-decoration: none;
          @extend %hoverAnimation;
        }


      }
    }
  }
}

.logo {
  img {
    height: 80%;
  }
}

@media (max-width: 770px) {

  .boxTopBar {
    .list {
      display: none !important;
    }
  }
}
