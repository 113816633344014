@import "../../../styles/settings.scss";

.root {
  background-color: $color-grey-medium;
  box-shadow: inset 0 0 1em $color-grey-line;

  .box {
    padding: 80px 50px;
  }
}
