@import "../../../styles/settings.scss";

.box {
  min-width: 140px;
  min-height: 120px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 4px 10px 4px $color-grey-text;
  background-color: $color-white;
  border-radius: 10px;

  &:hover {

    .icon {
      height: 40px;
      transition: 0.5s linear;
    }
    .text {
      display: flex;
      font-size: 16px;

    }
  }

  .icon {
    height: 60px;
    color: $color-top;
  }

  .text {
    display: none;
    font-family: $font-text;
    color: black;
    font-weight: 900;
    padding-top: $base-padding;
    text-transform: uppercase;
  }
}

@media (max-width: 770px) {

  .box {
    .text {
      display: flex;
    }
  }
}
